const sizes = {
  navBarHeight: '3rem',
  heroHeight: 50,
  padding05: '0.5rem',
  padding1: '1rem',
  padding2: '2.5rem',
  paddingSm: 'calc(1rem + 1.5vw)',
  paddingMed: 'calc(1.5rem + 2.5vw)',
  paddingL: 'calc(3rem + 2.5vw)',
  paddingXL: 'calc(3rem + 5vw)',
  fontHead2: ' calc( 1.5rem + 0.5vw )',
  smScreen: '450px',
  medScreen: '800px',
  wideScreen: '950px',
  processTextMax: '60rem',
}

export default sizes
