import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-plugin-transition-link'
import TransitionLink from 'gatsby-plugin-transition-link'
import styled, { keyframes } from 'styled-components'

import LinkUnderlined from './link--underlined'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'

// import SimpleHeader from './header--simple'

import '../layouts/global.scss'

let borderThickness = '0.5rem'

const NameGrid = styled.ul`
  /* background-color: ${colors.homeBackground}; */
  display:grid;
  padding: 0;
  margin:0;
  

  @media (min-width: ${sizes.wideScreen}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 100vh;
    /* grid-template-rows: 1fr 1fr 1fr; */
  }
`

const NameTile = styled.li`
  text-decoration: none;
  display: grid;
  align-items: center;

  @media (min-width: ${sizes.wideScreen}) {
    height: 33.33vh;
  }

  p,
  h1 {
    margin: 0;
    font-size: 1rem;
    @media (min-width: ${sizes.wideScreen}) {
      font-size: 1.25rem;
    }
  }
`
const HomeTile = styled(NameTile)`
  background-color: white;
  padding: ${sizes.padding1};
  display: flex;
  justify-content: space-between;

  border: transparent ${borderThickness} solid;

  @media (min-width: ${sizes.wideScreen}) {
    align-items: flex-start;
    flex-direction: column;
  }
  a {
    color: ${colors.text};
    font-weight: 700;
    text-decoration: none;
    transition: 0.25s;

    &:focus {
      color: ${colors.accentColorful};
      outline: none;
    }

    @media (hover: hover) {
      &:hover {
        color: ${colors.accentColorful};
      }
    }
  }
`

const SiteTitle = styled.div`
  a {
    text-decoration: none;
  }
`

const ProjectLink = styled(Link)`
  /* height: 100%; */

  align-self: stretch;
  display: grid;
  background: ${props => props.customcolor};
  border: transparent ${borderThickness} solid;
  /* padding: ${sizes.paddingSm}; */
  text-decoration: none;
  color: ${colors.accent};
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 400;
  transition: 0.25s;

  text-align: left;
  grid-template-columns: 33.33% 1fr;
  grid-gap: ${sizes.paddingSm};

  @media (min-width: ${sizes.wideScreen}) {
    /* grid-gap: 0; */
    /* height: 33.33vh; */
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    /* grid-template-rows: 60% min-content; */
    text-align: center;
    /* height:100%; */
    grid-gap:0;
  }

  p {
    /* font-size: 1.25rem; */
    margin: 0;
  }

  .label {
    display:flex;
    flex-direction: column;
    justify-content:center;

    @media (min-width: ${sizes.wideScreen}) {
      padding-bottom: ${sizes.padding1};
    }
  }

  .project {
    font-weight: 700;
  }

  &:focus {
    background: ${colors.text};
    outline: none;
  }

  @media (hover: hover) {
      &:hover {
        background: ${colors.text};
      }
    }
`

const items = 10
const duration = 10
const fade = 0.01

const cycleGlyphs = keyframes`
  0%, ${100 / items - fade}% { 
    opacity: 1; 
  }
  ${100 / items}%, ${100 - fade}% {
    opacity: 0;
  }
`

function template(i, items, duration) {
  return `
      &:nth-child(${i + 1}) {
        animation-delay: ${`${(duration / items) * i}s`};
       }
    `
}
function getAnimations(items, duration) {
  let str = ''
  for (let i = 0; i < items; i += 1) {
    str += template(i, items, duration)
  }
  return str
}

const phoneGlyphHeight = 'calc(90px + 10vw)'

const ProjectGlyphs = styled.div`
  overflow: hidden;
  display: grid;
  justify-content: center;

  height: ${phoneGlyphHeight};

  ul {
    padding: 0;
    display: grid;
    align-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    li {
      grid-row: 1;
      grid-column: 1;
      opacity: 0;
      will-change: opacity;
      animation: ${cycleGlyphs} ${duration}s linear infinite;
      ${getAnimations(items, duration)}

      @media (prefers-reduced-motion: reduce) {
        animation: none;
        -webkit-animation: none;
        &:nth-child(1) {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: ${sizes.wideScreen}) {
    height: calc(33.33vh * 0.7);
  }

  li,
  img {
    height: ${phoneGlyphHeight};
    max-width: 100%;
    @media (min-width: ${sizes.wideScreen}) {
      height: calc(33.33vh * 0.7);
    }
  }
`

class GridOfNames extends React.Component {
  render() {
    const { data } = this.props
    const namegrid = data.allMdx.edges
    const glyphs = data.allFile.edges
    function glyphsPerProject(designer) {
      const firstName = designer
        .split(' ')[0]
        .toLowerCase()
        .replace('ł', 'l') // support Rafał's name
        .replace('á', 'a') // support Seán's name
      const projectGlyphs = glyphs.map((glyph, index) =>
        glyph.node.relativePath.includes(firstName) ? (
          <li key={index}>
            <img src={glyph.node.publicURL} alt="" />
          </li>
        ) : (
          ''
        )
      )
      return projectGlyphs
    }

    // let randomNumber = () => Math.floor(Math.random() * 100)

    return (
      <div>
        <NameGrid>
          <HomeTile>
            {/* <SimpleHeader /> */}
            <SiteTitle>
              <h1>
                <TransitionLink
                  tabIndex="0"
                  to="/"
                  exit={{
                    length: 1,
                  }}
                  entry={{
                    delay: 0,
                  }}
                >
                  TypeMedia 2018
                </TransitionLink>
              </h1>
            </SiteTitle>
            <LinkUnderlined>
              <p>
                <TransitionLink
                  to="/about"
                  exit={{
                    length: 1,
                  }}
                  entry={{
                    delay: 0,
                  }}
                >
                  About
                </TransitionLink>
              </p>
            </LinkUnderlined>
          </HomeTile>

          {namegrid.map(({ node }) => (
            <NameTile
              className="listing"
              key={node.id}
              // style={{ order: Math.floor(Math.random() * 100) }} // doesn't work well. :/
            >
              <ProjectLink
                to={`/${node.fields.slug}`}
                customcolor={node.frontmatter.homepageColor}
              >
                <ProjectGlyphs>
                  <ul>{glyphsPerProject(node.frontmatter.designer)}</ul>
                </ProjectGlyphs>
                <div className="label">
                  <div>
                    <p className="project">{node.frontmatter.project}</p>
                    <p>{node.frontmatter.designer}</p>
                  </div>
                </div>
              </ProjectLink>
            </NameTile>
          ))}
        </NameGrid>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMdx(sort: { order: ASC, fields: [frontmatter___project] }) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                project
                homepageColor
                designer
              }
              fields {
                slug
              }
            }
          }
        }
        allFile(
          filter: {
            extension: { eq: "svg" }
            relativePath: { regex: "/top-glyphs/" }
          }
          sort: { order: ASC, fields: [relativePath] }
        ) {
          edges {
            node {
              relativePath
              publicURL
            }
          }
        }
      }
    `}
    render={data => <GridOfNames data={data} {...props} />}
  />
)
