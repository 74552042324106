import React from 'react'
import styled from 'styled-components'

const UnderlinedLink = styled.div`
  display: inline;
  a {
    border-bottom: ${1 / 8}em solid;
    padding-bottom: 1px;
  }
`
const LinkUnderlined = ({ children }) => (
  <UnderlinedLink>{children}</UnderlinedLink>
)
export default LinkUnderlined
